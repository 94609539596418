.refresh-wallet {
    position: absolute;
    width: 96%;
    height: 89%;
    top: 0px;
    left: 17px;
}

.amount-result {
    font-size: 20px;
}

.item-count {
  position: absolute;
  top: 5px;
  left: 9px;
  //font-weight: bold;
  font-size: 11px;
  opacity: 0.5;
}
.balancess-data {
  margin-bottom: 0;
}
.balancess-data.btn-group .btn {
  padding: 4px 15px;
}
.pending-payment {
  margin: -4px 0 32px 0;
  //background: #4de1b5;
  border-radius: 10px;
  padding: 3px 10px;
  display: inline-block;
  color: white;
}
.pending-payment:hover {
  color: white;
}
.wallet-dropdown .dropdown-menu {
  left: -108px;
  border-radius: 10px;
}

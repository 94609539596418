.liquidity {

  .btn-group.btn-group--icons .btn {
    padding: 7px 14px;
  }

  .token-name {
    margin-left: 52px;

    .token-code {
      font-size: 18px;
      font-weight: bold;
    }
    .token-name {
      margin-top: -25px;
      text-align: right;
    }
    .token-domain {
      margin-top: -5px;
    }
    .token-issuer {
      margin-top: -23px;
      text-align: right;
    }
  }
  .asset-info {
    font-size: 14px;
    td {
      padding-left: 0;
      padding-right: 0;
    }
  }


  .native-item {
    padding: 0;
  }

  .right-pair {
    position: absolute;
    right: 0;
  }



}
// pool /


.icon-asset-pair {
  width: 43px;
  background: white;
  border-radius: 10px;
  padding: 3px;
  position: absolute;
  border: 1px solid #80808038;
  text-align: center;
  height: 43px;

  img {
    //width: 30px;
  }
}





.liquidity-item {

  padding-bottom: 11px;

  .item {
    //margin-top: -4px;
  }
  .card-body {
    border-radius: 20px;
  }
  .star-icon {
    width: 26px;
    float: left;
    margin: 12px -14px 0 -6px;
  }
  .icon-asset-pair {
    width: 40px;
    height: 40px;
  }

  .card-body {
    padding: 20px 25px 17px;
    border: 1px solid #80808038;
  }
}

@media screen and (max-width: 480px) {

  .liquidity-item {

    .item {
      margin-bottom: 15px;
      //margin-top: -4px;

      &:first-child {
        margin-bottom: 24px;
      }

      .liquid-btn {
        margin: 14px 0 -7px 0;
      }
    }
    .hide-xs {
      display: none;
    }
  }

  .toolbar-filter {
    margin-bottom: 20px;
  }
}


.pool-square-sm {
  background: white;
  border-radius: 7px;
  padding: 1px 4px 3px 4px;
  margin-top: -5px;
  display: inline-block;
}


.clear-icon {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: -4px;
  padding-right: 9px;
  padding-left: 9px;
}

.last-tx-card {
  margin: 28px 0 -28px 0;
  border: 1px solid #80808014;
  padding-bottom: 0;
}
.search-pool-form {
  width: 200px;
  margin-top: 25px;
}
.filter-pools {
  .span {
    font-size: 12px !important;
  }
}
.filter-row {
  margin-bottom: -25px;
  margin-top: 25px;
}
.pool-counter {
  position: absolute;
  left: 12px;
  top: 6px;
  color: #6c757d5e;
}
.toolbar-filter {
  margin-top: 17px !important;
  //margin-bottom: 0px;
}
.not-history {
  display: table-cell;
  vertical-align: middle;
}
.rewards {
  font-size: 12px;
  margin-top: -1px;
  margin-bottom: -10px;
}
.color-scop {
  background: #546cece3;
}
.color-aqua {
  background: #882ab054;
}
.label-rewards {
  //background: #f7fb084d;
  //background: #882ab0c4;
  //background: #882ab054;
  padding: 3px 9px;
  border-radius: 10px;
  display: block;
  width: max-content;
  color: white;
  //color: #727d6cd4;

  //background: #882ab0;
  //padding: 3px 9px;
  //border-radius: 5px;
  //display: block;
  //width: max-content;
  //color: white;

  b {
    //color: #727d6cd4;
  }
}
.pool-color-scop {
  background: #546cece3;
}
.pool-color-aqua {
  background: #9848bbe6;
}
.pool-rewards {
  position: absolute;
  right: 32px;
  margin-top: -2px;
  margin-bottom: -10px;
  //background: #9848bbe6;
  //background: #f7fb084d;
  padding: 2px 9px;
  border-radius: 10px;
  display: block;
  width: max-content;
  color: white;
  //color: #727d6cd4;
}





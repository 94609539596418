.icon-favorit {
  position: absolute;
  right: 14px;
  cursor: pointer;
}

.asset-info {
	//font-size: 12px;
}

.asset-info {
	 font-size: 12px;

	tr td {
		&:last-child {
		  float: right;
		}
	}
}

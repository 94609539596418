
.text-warning {
  color: #ffc107e0 !important;
}

.uppercase {
  text-transform: uppercase;
}
.low-uppercase {
  text-transform: lowercase;
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.modal-dialog--swap {
  max-width: 435px !important;
}

.btn.btn-purple {
  background-color: #8b74fe;
  border-color: #66a3f9;
  color: white;
}

.tooltip {
  z-index: 10000000000;
}

.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-26 {
  font-size: 26px;
}
.fs-28 {
  font-size: 28px;
}
.fs-43 {
  font-size: 43px;
}
.fs-60 {
  font-size: 60px;
}
.fs-80 {
  font-size: 80px;
}

.fs-460per {
  font-size: 460%;
}

.p-0 {
  padding: 0px;
}

.fl-l {
  float: left;
}

.m-0 {
  margin: 0 0 0 0 !important;
}
.m-min-6 {
  margin: -6px -6px;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-6 {
  margin-bottom: 6px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-14 {
  margin-right: 14px !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-4 {
  margin-left: 4px !important;
}
.ml-6 {
  margin-left: 6px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-47 {
  margin-left: 47px;
}
.ml-44 {
  margin-left: 44px;
}
.ml-42 {
  margin-left: 42px;
}
.ml-37 {
  margin-left: 37px;
}
.ml-75 {
  margin-left: 75px;
}
.ml-86 {
  margin-left: 86px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-100 {
  margin-left: 100px;
}
.ml-102 {
  margin-left: 102px;
}

.mt-2 {
  margin-top: 2px;
}
.mt-6 {
  margin-top: 6px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-27 {
  margin-top: 27px;
}
.mt-35 {
  margin-top: 35px;
}

.mt-min-6 {
  margin-top: -6px;
}
.mt-min-10 {
  margin-top: -10px;
}

.b-r {
  border-radius: 5;
}

.br-5 {
  border-radius: 5px;
}


.w-60 {
  width: 60%;
}
.w-100 {
  width: 100%;
}


main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  //font-family: 'Baloo 2'
  font-size: 13px;
  line-height: 1.6;
}

* {
  box-sizing: inherit;
}

ul, ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}
.asset-code {
  margin-right: 15px;
}

.asset-amount {
  margin-right: 3px;
}

div, p {
  font-size: 14px;
}

//
.spinner {
  margin: 3px auto -4px;
  width: 80px;
  text-align: center;
}

.spinner > div {
  width: 16px;
  height: 16px;
  background-color: #7e7f8f;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.recovery-token {
  background: #e6e6e62e;
  border-radius: 10px;
  padding: 30px;
}

.highcharts-credits {
  display: none;
}

.ex_pair {
  margin-right: 25px;
  display: 'inline-block';
}

.dir-ex-txt {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.my_elem {
  border: 1px solid rgba(84, 229, 187, 0.71);
}

.subhead-b {
  word-break: break-all;
  margin-bottom: 18px;
}

.table-btn {
  margin-bottom: 0 !important;
}

.cur-index {
  width: 20px;
  position: absolute;
  top: 13px;
  left: 0px;
  color: gray;
}

.text-danger {
  color: #fb7d89 !important;
}
.text-success {
  color: #4ce1b6 !important;
}
.text-secondary {
  color: gray;
}

.topbar-active {
  border-bottom: 2px solid #42e2b4;
}

.land_logo {
  width: 145px;
  margin-top: -17px;
}

.landing_icon {
  font-size: 60px;
  color: #8de5f8;
}

.landing_big_icon {
  font-size: 90px;
  color: #4496ae;
}

.top-stellar-icon {
  width: 18px;
  filter: invert(1);
  margin-top: -2px;
}

.footer-icon {
  background: #ccfae8;
  border-radius: 50%;
  padding: 9px 14px;
  margin-right: 14px;
  font-size: 18px;
  color: #16a2b8;
  height: 47px;
  width: 47px;
  display: inline-block;
  border: 1px solid #2c85a11a;
  transition: 0.3s;
  background-color: #ffffff;
  box-shadow: 0 20px 30px 0 rgba(89, 181, 210, 0.31);
}

.platforms-icon {
  //background: #ccfae8;
  border-radius: 22%;
  padding: 7px 15px;
  margin-right: 10px;
  font-size: 18px;
  // color: #6bbafe;
  height: 47px;
  width: 47px;
  display: inline-block;
  border: 1px solid #2c85a11a;
  transition: 0.3s;
  background-color: #ffffff;
  box-shadow: 0 20px 30px 0 rgba(89, 181, 210, 0.31);
}

.badge-round {
    color: #ffffff;
    padding: 6px 20px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 400;

    &.badge-success {
      background-color: $color-accent;
    }

    &.badge-warning {
      background-color: $color-yellow;
    }

    &.badge-primary {
      background-color: $color-blue;
    }

    &.badge-danger {
      background-color: $color-red;
    }

    &.badge-disabled {
      background-color: #dddddd;
    }
  }

// .carousel-caption {
//   h3, p {
//     color: white !important;
//   }
// }

.mb-14 {
  margin-bottom: 18px;
}

.chips_item {
  border: 1px solid #8080802b;
  border-radius: 11px;
  padding: 11px 10px 0px 12px;
  margin: 0 10px 0 0;
  display: inline-block;
  height: 62px;
}
.chips_item_word {
  border: 1px solid #8080802b;
  border-radius: 11px;
  padding: 3px 8px;
  margin: 0 4px 4px 0;
  display: inline-block;
}
.wr-balance-poll-item {
  //background: white;
  border-radius: 10px;
  display: inline-block;
  margin-right: -4px;
}
.balance-pool-item {
  display: block;
  margin-top: -17px;
  padding:16px 5px 4px 0px;
}

.toggle-btn__input-label {
  margin-left: initial !important;
}

.wr-account-btn {
  a {
    margin-left: 7px;

    span {
      font-size: 15px;
    }
  }
}

.addr-lable {
  position: absolute;
  top: 6.3%;
  left: 17%;
}

.create-field {
  font-size: 12px;
  background: #f6f6f6;
  border-radius: 3px;
  padding: 30px;
}
.page-title {
  // text-transform: inherit;
}

.name-asset {
  white-space: nowrap;
  overflow: hidden;
  padding: 5px;
  text-overflow: ellipsis;
  width: 103px;
  display: block;
}

.ratings {
  border: 1px solid #80808040;
  border-radius: 10px;
}

.reward {
  // https://svgsilh.com/svg_v2/305501.svg
  // https://cdn.pixabay.com/photo/2012/04/12/19/21/olive-30264_960_720.png
  // http://severnoebutovomedia.ru/upload/iblock/35f/35f1eba754baffe473a518afa93d0330.png
  background-image: url(/img/icons/reward.png);
  height: 61px;
  background-size: 23%;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-top: 8px;
  font-size: 20px;
  font-weight: bold;
}

.card .item-list {
  padding: 20px 10px 10px;
  border: 1px solid #8080801f;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  .card-title {
    font {
      float: right;
      font-size: 11px;
      color: white;
      padding: 0 6px;
      margin-right: -10px;
    }
  }

  p {
    font-size: 13px;

    font {
      float: right;
    }
  }
}

.status-ieo {
  float: right;
  font-size: 11px;
  color: white;
  padding: 0 10px;
  margin-left: 8px;
}

.ieo-current {
  background: #4ce1b6;
}
.ieo-future {
  background: #70bbfd;
}
.ieo-ended {
  background: gray;
}
.ieo-undefined {
  background: gray;
}

.ieo-verify {
  width: 25%;
  position: absolute;
  right: 5px;
  top: 8px;
}
.verify-text {
  color: #3e63a7;
  font-size: 10px;
  float: right;
  margin: 5px 8px -31px 0;
}
.team-photo {
  border-radius: 50%;
  width: 65%;
  /* border: 3px solid #4ce1b5; */
}

.inner-kyc {
  position: absolute;
}

.add-ido {
  position: absolute;
  right: 0;
  top: 0;
}
.wr-docs a {
  font-size: 12px;
}
.form__form-group-icon.up-bench-btn {
  padding: 6px 16px;
  background: #ecfff9;
}

.team-avatar {
  border-radius: 50%;
  border: 1px solid #676a7a;
  width: 116px;
}

.sm-trades {
  font-size: 12px;

  div {
    font-size: 12px;
  }
}

.status-badge {
  font-size: 8px;
  font-weight: 400;
  padding: 4px 8px 3px 8px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;
  float: right;
}

.subhead-ido {
  margin-top: 24px;
  font-size: 12px !important;
}

ul.share-buttons{
  list-style: none;
  padding: 0;
}

ul.share-buttons li{
  display: inline;
}

ul.share-buttons .sr-only{
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.st-logo {
  display: none;
}

hr {
  border-top: 1px solid rgba(242, 244, 247, 1);
  //border-top: 1px solid rgb(242 244 247);
}


.theme-dark {

  .search-input {
    border: 1px solid #353535 !important;
  }
  .search-btn {
    border: 1px solid #353535 !important;
  }

  .icon-asset-pair {
    //background: #2a2a30 !important;
  }

  .btn.btn-outline-secondary {
    border-color: #535357;
  }

  .notification {
    background-color: #2a2a31 !important;

    .item-offer {
      background: #38373f !important;
    }
  }

  #st-1 .st-btn {
    border: solid 1px #4a4f56 !important;
    background-color: #3e3e42 !important;
  }

  .card-body-border {
    border: 1px solid #38373f !important;
  }

  hr {
    border: 0;
    border-top: 1px solid #33333a !important;
  }

  .profile__activity {
    border-bottom: solid 1px #4f5056;
  }

  .nav-tabs {
    border-bottom: 1px solid #4f5055;
  }

  .btn.btn-secondary {
    background-color: #2a2b30;
    border-color: #5e5e61;
  }
}

.sticky-buy {
  position: sticky;
  top: 76px;
}
.auth-bl {
  margin-bottom: 40px !important;
}
.card-body-border {
  border: 1px solid #ebebeb !important;
}

.badge {
  color: #ffffff;
  padding: 6px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 400;

  &.badge-success {
    background-color: $color-accent;
  }

  &.badge-warning {
    background-color: $color-yellow;
  }

  &.badge-primary {
    background-color: $color-blue;
  }

  &.badge-danger {
    background-color: $color-red;
  }

  &.badge-disabled {
    background-color: #dddddd;
  }
}

.image-id {
  width: 32px;
  margin-top: -2px;
  border: 0.5px solid #8080805c;
  padding: 4px;
  border-radius: 50%;
}

.image-icon {
  // height: 29px;
  // width: 30px;
  height: 40px;
  width: 42px;
  float: left;
  margin-right: 10px;
}
.icon-indent {
  width: 12px;
  margin-top: -3px;
}

.icon-ident-id {
  margin-top: -2px;
  width: 20px;
  border: 0.5px solid #8080805c;
  padding: 3px;
  border-radius: 50%;
}

.icon-indent-sm {
  width: 17px;
  margin-top: -3px;

  border: 0.5px solid #8080805c;
  padding: 3px;
  border-radius: 50%;
}
.icon-indent-xs {
  width: 16px;
  margin-top: -2px;
  border: 0.5px solid #8080805c;
  padding: 3px;
  border-radius: 50%;
}
.icon-indent-xl {
  width: 37px;
  border: 0.5px solid #8080805c;
  padding: 3px;
  border-radius: 50%;
}
.circle-icon-xs {
  background: white;
  border-radius: 50%;
  border: 0.5px solid #80808036;
  padding: 2px 5.1px 0 5.5px;
  margin-right: 3px;
  display: inline-block;
}

.depth-bl .highcharts-range-selector-group {
  display: none !important;
}

.rating-info {
  text-transform: none;
  font-weight: normal;
}
.notification-dark {
  background: #38373f !important;
  border: 1px solid #5d707736 !important;
}
.home_domain {
  margin-top: -4px;
  display: block;
  color: gray;
}
.name_domain {
  padding-top: 3px !important;
  padding-bottom: 2px !important;
}
.collapse__wrapper.with-shadow {
  margin-left: 10px;
}

.wr-phrase {
  background: #f2f4f70d;
  border-radius: 10px;
  padding: 30px 15px;
  border: 1px solid #f2f4f714;
}
.check-phrase {
  font-size: 14px;
  min-height: 100px;
  border-radius: 10px;
}
.wizard__step:first-child {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.wizard__step:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}


.wallet-dropdown {
  margin: -8px -8px !important;
}
.wallet-dropdown .dropdown-toggle {
  padding: 5px 12px 4px 10px !important;
  margin: -18px -13px 0 0 !important;
  background-color: #e7e2e20d !important;
  border-color: #e7e2e282 !important;
}

.uri-str {
  padding: 0px 8px 10px 10px;
  text-overflow: ellipsis;
  display: inline-block;
}
#react-qrcode-logo {
  border-radius: 10px;
  border: 3px dotted #217fe42e;
}

.fa-question-circle-o {
  cursor: pointer;
  //color: #70bbfd;
}
.native-logo {
  width: 20px;
  margin: -3px 3px 0 8px;
}
.pay-crypto {
  width: 23px;
  margin: -3px 0 0 4px;
}
.card-icon {
  width: 20px;
  margin: 1px 0 0 7px;
  height: 12px;
}
.assets-carousel {
  margin: 9px 0 19px 0;
}
.wr-asset-item-icon {
  float: left;
  margin-right: 8px;
}
.asset-item-icon-top {
  width: 20px;
}
.asset-domain {
  font-size: 8px;
  color: gray;
  // margin: -3px 0 -4px 3px;
  margin: -5px 0 -2px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-logo-wr {
  font-size: 80px;
  position: relative;

  .search-stellar-logo {
    width: 21px;
    position: absolute;
    left: 21px;
    top: 28px;
  }
}

.modal-content .modal__title {
  color: white !important;
}

.modal-dark {
  background-color: #232329 !important;
  color: gray;

  input, label {
    color: gray !important;
  }
  input:before,
  .MuiInput-underline-34:before,
  .MuiInput-underline-131:before,
  .MuiInput-underline-79:before,
  .MuiInput-underline-106:before,
  .MuiInput-underline-27:before {
    border-bottom: 1px solid #8080802b !important;
  }
}

.modal-dark .material-form input:before {
  border-bottom: 1px solid #8080802b !important;
}

.text-lightblue {
  color: #70bbfd;
}

//.modal-backdrop.show {
//  opacity: 0.1;
//}

.login-btns {
  button {
    width: 200px;
    text-align: left;
    font-size: 16px;
  }
  img {
    width: 10%;
    margin-right: 6px;

  }
}

.swal-modal {
  border-radius: 10px;
  button {
    padding: 12px 25px;
    border-radius: 10px;
  }
  .swal-button--confirm {
    background-color: #70bbfd;
    border-color: #70bbfd;
  }
  .swal-button--cancel {
    background-color: #e7e2e2;
    border-color: #e7e2e2;
    color: #646777;
  }
  .swal-button:focus {
    outline: none;
    box-shadow: none;
  }
}

.theme-dark {

  .circle-icon-xs {
    background: #ffffff08;
  }
  .domain {
    border-top: 1px solid #2c2c3a !important;
  }
  .swal-modal {
    background-color: #232329; // #2a2a30;

    div {
      color: #a5a5a5;
    }

    .swal-icon--info {
      border-color: #a5a5a5;
      &:after, &:before {
        background-color: #a5a5a5;
      }
    }

    .swal-icon--success__hide-corners {
      background-color: transparent;
    }

    .swal-icon--success:after, .swal-icon--success:before {
      background: none;
    }
    .swal-button--cancel {
      background-color: #1d1d1f;
    }
  }

  .toast-info {
    background-color: #2a2a30 !important;
    color: #a5a5a5;
  }
}

.provider-sm-icon {
  width: 12px;
  margin-left: 12px;
}

.swal-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
.swal-title {
  font-size: 22px;
}
.swal-text {
  font-size: 14px;
}
.connect-provider {
  background: #c8eefb !important;
}
.accept-token-logo {
  width: 24px;
  margin-left: 5px;
}
.notifications-bc {
  margin: 0px auto 0px -180px !important;

  .notification-dismiss {
    font-size: 22px !important;
    top: 7px !important;
    right: 7px !important;
    background-color: transparent !important;
    color: gray !important;
  }
}

.title-auth-block {
  margin-bottom: 20px;
  text-align: left;
}
.btn-xss {
  padding: 2px 10px !important;
  font-size: 10px !important;
  margin-bottom: 2px !important;
}
.padding-cardbody-16 {
  .card-body {
    padding: 16px;
  }
}
.page-title {
  font-size: 18px !important;
}
.sidebar-img-icon {
  width: 16px;
  margin-top: -3px;
}
.topbar-img-icon {
  margin-top: 21px;
  width: 19px;
  margin-right: 7px;
}
.topbar-img-send {
  margin-top: 23px;
  margin-right: 13px;
  display: inline-block;
  color: #a6a6a6;
  font-size: 16px;
}

.success-status {
  background: #4ce1b6;
  padding: 3px 6px 3px;
  border-radius: 5px;
  color: white;
}
.pending-status {
  background: #bce6ff;
  padding: 2px 6px 3px;
  border-radius: 5px;
  color: #4786ab;
}
.upcoming-status {
  background: #80808052;
  padding: 2px 6px 3px;
  border-radius: 5px;
  color: #868686;
}
.expired-status {
  background: #fcc7cd;
  padding: 2px 6px 3px;
  border-radius: 5px;
  color: #fc8d97;
}

//create-claimant
.stats-claimants {
  b {
    float: right;
  }
}

.collapse__wrapper.opened .collapse__title p,
.collapse__wrapper.opening .collapse__title p {
  color: #646777 !important;
}
.services-start {
  a {
    color: gray;
  }
}
.word-break {
  word-break: break-all;
}
.asset-icon-sm {
  width: 16px;
  margin-top: -3px;
}
.asset-icon-balance {
  width: 20px;
  margin-top: -4px;
}

.pool-share {
  border: 1px solid #80808047;
  padding: 5px 3px 7px 3px;
  border-radius: 8px;
  margin: 0 2px 0 0;
  //background: white;
}
.relative {
  position: relative;
}
.absolute-right {
  position: absolute;
  right: 0;
  top: 0;
}
.material-field-icon {
  position: absolute;
  top: 19px;
  right: 21px;
  .fa {
    color: gray;
  }
}
.reverse-assets {
  text-align: center;
  margin: -8px 0 9px 0;
}
.min-details {
  min-width: 267px;
  display: block;
}
.token-info {
  margin-bottom: 12px;

  b {
    float: right;
  }
  p {
    font-size: 13px;
  }
}
.img-exchange {
  width: 60%;
  opacity: 0.7;
}

.allocation-block {
  margin: 0 0 7px 30px;

  span {
    font-size: 12px;
  }

  div {
    margin-bottom: 3px;
  }
}


.apexcharts-svg {
  background: transparent !important;
}

.theme-light .apexcharts-tooltip {
  background: white;
  color: gray;
  //opacity: 0.9;
}

.popover {
  z-index: 10000000000 !important;
}

.reward-asset-icon {
  width: 25px;
  margin-top: -4px;
}
.reward-asset-icon-top {
  width: 30px;
}

.item-reward {
  padding-top: 12px;
  padding-bottom: 10px;
  transition: background-color 100ms linear;
}
.item-reward:hover {
  background-color: #dcdcdc36;
  //transition: background-color 1000ms #dcdcdc36;
  border-radius: 8px;
}
.opacity-reward {
  opacity: 0.7;
}
.soon-icon {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 28px;
  color: #6c757d;
}
.badge-block {
  margin: 12px 0 14px 0;
}
.tooltip-7d {
  padding: 0 4px;
}
.theme-dark .tooltip-7d {
  background: black;
}
//.notification-title {
//  color: #47d47a !important;
//}


.right-pair {
  position: absolute;
  right: 0;
}

.icon-asset-pair-market {
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 10px;
  padding: 2px;
  //position: absolute;
  border: 1px solid #80808038;
  text-align: center;
  float: left;

  img {
    //width: 30px;
  }
}

.trustline-mess-success {
  background: #47d47a21;
  border-radius: 10px;
  padding: 20px 8px;
}
.trustline-mess-error {
  background: #fb7d892e;
  border-radius: 10px;
  padding: 20px 8px;
}

.calc input {
  font-size: 16px !important;
  font-weight: bold;
}
.calc-select-asset-icon {
  width: 20px;
  margin-top: -3px;
}
.material-label {
  position: absolute;
  bottom: 25px;
  right: -1px;
}
.asset-icon-xs {
  width: 14px;
  margin-top: -1px;
}


.element-animation {
  //margin: 2rem 0;
  //font-size: 3rem;

  opacity: 0;
  transform: translateY(50px);
}

.element-animation.element-show {
  opacity: 1;
  transition: all 1.5s;
  transform: translateY(0%);
}

.top-icon {
  //background: linear-gradient(80deg, rgba(0, 111, 255, 1), rgba(154, 111, 255, 1));
  border-radius: 50%;
  padding: 9px 16px;
  font-size: 40px;
  //color: white;
}

.create-top-btn {
  background: #4ce1b6;
  color: white;
  display: block;
  padding: 5px 10px 2px 10px;
  margin-top: 15px;
  border-radius: 10px;
  margin-left: 6px;
}

// == Scrol to top ================\
.scroll-to-top {
  opacity: 0.3;
  background-color: gray;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 10px;
  border: none;
  z-index: 10;

  &:hover {
    opacity: 0.9;
  }

  i {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -5px;
  }
}
//===========================/


.datepicker-wrapper .react-datepicker-wrapper {
  width: 100%;
}

.h1-stellar {
    width: 40px;
    filter: invert(1);
    margin: -5px -6px 0 0;
}

.swap-data {
  margin-top: -22px;
  text-align: right;
  display: block;
}
.stats-block {
  div {
    color: gray !important;

    .success-impact {
      color: rgb(71, 212, 122) !important;
    }
  }
}
.create-claimable {
  .datepicker-wrapper {
    margin-right: 0px;
  }
}
.field-right {
  position: absolute;
  right: 0;
  bottom: 26px;
}

.create-word-wrap {
  word-wrap: break-word;
  width: 290px;
}

.label-footer {
  background: #07a5dd;
  color: white;
  border-radius: 10px;
  padding: 2px 7px;
}
.search-input {
  height: 50px !important;
  font-size: 13px !important;
  border: 1px solid #35353517 !important;
}
.search-btn {
  width: 90px !important;
  height: 50px !important;
  position: absolute !important;;
  right: 0;
  border: 1px solid #35353517 !important;
}
.dropdown-round-btn {
  padding: 4px 7px 2px !important;
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 50% !important;
}
.wallet-dropdown button {
  padding: 7px 0 7px 18px;
}
.avatars {
  display: flex;
}
.avatars__item {
  margin-left: -0.25rem;
}
.avatars__image {
  box-shadow: 0 0 0 0.15rem #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 9999px;
  width: 30px;
  background: #e4e3e2;
}
.wallet-button {
  background: #454546;
  border-radius: 50%;
  padding: 12px 14px;
  font-size: 18px;
  transition: 0.3s;
  //transition: all 0.4s;

  &:hover {
    background: #a6a3a3;
  }
}


.qr-address {
  width: 240px;
  display: inline-block;
}
.modal-vertical {
  top: 25%;
  transform: translateY(-25%) !important;
}
.share-item {
  margin: 5px;
}




.space-between {
  display: flex;
  justify-content: space-between;
}
.justify-content-start {
  display: flex;
  justify-content: start;
}
.space-around {
  display: flex;
  justify-content: space-around;
}
.space-center {
  display: flex;
  justify-content: center;
}





.toast {
  flex-basis: 0 !important;
  border: 1px solid transparent !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

h4.notification-title {
  color: rgb(0, 240, 139) !important;
}
.notification.notification-success.notification-visible {
  border-top-color: rgb(0, 240, 139) !important;
}

.date-picker-popover .popover {
  width: 100% !important;
  max-width: 311px;
}


//.claim-card {
//  height: 635px;
//  visibility: hidden;
//  margin-top: -100;
//}








.swap {

  .swap-item {
    border: 1px solid #80808014;
    border-radius: 10px;
    padding: 20px;
  }

  .balance-label {
    text-align: right !important;
    display: block;
    margin: -21px 0 9px 0;
  }

  .input-swap {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 14px;
    height: 50px;
    font-weight: bold;
  }

  .form__form-group-icon.select-asset-icon {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 4px 7px 10px 5px;
    width: 410px;
    height: 50px;
  }

  .form__button-toolbar {
    margin-top: 25px;
  }

  .icon-asset {
    width: 36px;
    float: left;
    margin-right: 5px;
  }

  .swap-asset-code {
    display: block;
    margin-top: 2px;
  }

  .swap-home-domain {
    margin: -5px 0 0 0;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .arrow {
    text-align: right;
    font-size: 12px;
    margin: -24px -3px;
  }


  .current-price {
    margin-bottom: -25px;
  }

  .br-5 {
    border-radius: 5px;
  }

  .custom-percent {
    width: 15%;
    background: #e7e2e2;
    border: none;
    font-size: 11px;
    padding: 5px 0 4px 7px;
    border-radius: 5px;
    color: #646777;
  }

  .slippage-tolerance {
    width: 43px;
    border: none;
    font-size: 14px;
    padding: 0px 0px 1px 0px;
    border-radius: 6px;
    margin-right: 4px;
    margin-bottom: 0;
  }
  .slippage-tolerance:focus {
    background-color: #70bbfd;
    color: white;
  }

  .collapse__content {
    padding-bottom: 0;
  }
  .show-details {
    height: 709px;
  }

  .clear-icon {
    margin: 7px 0 0 -27px;
    a {
      color: gray;
    }
  }

  .btn.btn-lgx {
    padding: 15px 25px;
    font-size: 15px;
  }


  .pool-rewards {
    position: absolute;
    right: -7px;
    margin-top: -3px;
    margin-bottom: -10px;
    padding: 2px 9px;
    border-radius: 10px;
    display: block;
    //width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    color: white;
  }



  @media screen and (max-width: 520px) {
    .card-body {
      padding: 10px;
    }

    .panel__btns {
      top: 10px;
      right: 10px;
    }
  }


}

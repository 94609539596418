
.refresh-bth {
    float: right;
    margin-top: 2px;
}
.qr-url {
    margin: 0 0 15px 0;
    display: inline-block;
    border: 1px solid #8080802e;
    border-radius: 10px;
    padding: 4px 10px;
}

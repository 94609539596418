.footer-back {
	background-image: url(/img/footer/wm-light.png);
	background-repeat: no-repeat;
	background-size: contain;
	padding-right: 50px;
}
.theme-dark .footer-back {
  background-image: url(/img/footer/wm-dark.png);
}


.footer-card .card {
  padding-bottom: 0;
}
.footer-card .card-body {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-bottom: 10px;
}

.footer-lable {
	margin-bottom: 8px;
}
.donat {
  width: 100%;
  word-wrap: break-word;
  float: right !important;
}
.stellar-logo-sm {
  width: 20px;
  margin-top: -4px;
}
.icon-apps {
	margin-bottom: 4px;
	margin-left: -2px;
  display: inline-block;
	width: 86px;
}

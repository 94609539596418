
.trade {

  .page-title {
      margin-bottom: 10px !important;
  }

  .wr-row {
    .trade-col {
      padding-right: 5px !important;
      padding-left: 5px !important;
    }
    .right-last {
      padding-right: 10px !important;
    }
    .card {
        padding-bottom: 10px !important;
    }
    .card-body {
        padding: 14px !important;
    }
  }

  .w-100 {
    width: 100%;
  }
  .w-100 button {
    width: 100%;
  }

  .trade-form input {
    height: 34px;
    font-size: 11px;
  }
  .trade-form button {
    margin-bottom: 11px;
  }
  .trade-form .form__form-group {
    margin-bottom: 10px;
  }
  .trade-form .form__button-toolbar {
    margin-top: 5px;
  }

  .orderbook-table {
    height: 457px;
    overflow: scroll;

    th {
      font-size: 12px;
    }
    tbody td {
      padding: 4px 5px 4px 5px;
    }

    .buy-offers {
      td {
        padding-right: 5px;
      }
    }
    .sell-offers {
      td {
        padding-left: 5px;
      }
    }
  }

  .panel__btns {
    top: 10px;
    right: 15px;
  }
  .asset-item {
    border: 1px solid #acaeb652;
    display: block;
    border-radius: 6px;
    padding: 6px 0;
  }

  .table-head {
    margin-bottom: 0;
    th {
      font-size: 12px;
    }
  }
  .trades-block {
    height: 446px;
    overflow: scroll;
    table {
      tbody td {
        padding: 4px 5px 4px 5px;
      }
      tr {
        border-bottom: 1px solid #a99f9f26;
      }
    // display: block;
    // position: relative;
    //
    // thead {
    //   th {
    //     padding: 4px 28px 4px 0px !important;
    //     font-size: 12px;
    //   }
    // }
    // tbody td {
    //   padding: 4px 4px 4px 8px !important;
    // }
  }
  //
  // table.table-body {
  //   height: 410px;
  //   overflow: scroll;
  //
  //
  //   .animationElem {
  //     // background-color: red;
  //     animation-name: newelem;
  //     animation-duration: 2s;
  //   }
  //
  //   @keyframes newelem {
  //     from {background-color: white;}
  //     to {background-color: transparent;}
  //   }
  // }
  // table.table-head {
  //   margin-bottom: 4px;
  // }
}

.text-success {
td {
  background: #38fd625c;
  // color: #47d47a !important;
}
}

.text-danger {
td {
  background: #ff838326;
  // color: #fb7d89 !important;
}
}

.item-enter {
opacity: 0;
}
.item-enter-active {
opacity: 1;
transition: opacity 500ms ease-in;
}
.item-exit {
opacity: 1;
}
.item-exit-active {
opacity: 0;
transition: opacity 500ms ease-in;
}

.orderbook-res-buy {
padding: 20px 0px 0 0;
// float: right;
}

.orderbook-res-sel {
padding: 20px 0 0 0px;
}

.wr-asset-icon {
float: left;
margin-right: 5px;
}

.asset-icon {
float: left;
width: 23px;
margin: 5px -2px 0 4px;
}

.wr-asset-code {
margin-left: 29px;
text-align: left;
}

.asset-code {
margin-top: -5px;
font-weight: bold;
margin-right: 0;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.asset-name {
font-size: 9px;
margin-top: -5px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.asset-domain {
font-size: 8px;
color: gray;
// margin: -3px 0 -4px 3px;
margin: -4px 0 -2px 1px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.profile__activity-avatar .icon-asset {
height: 50px;
width: 50px;
margin-top: 0;
}
.profile__activity-avatar {
  overflow: hidden;
  min-width: 64px;
  margin-right: -3px;
}
.profile__activity-code {
font-size: 20px;
}
.asset-info tbody td {
  padding: 9px;
}
.domain {
  font-size: 12px;
  border-top: 1px solid #eef1f5;
  padding: 9px 8px 0 7px;
}

.offer-notif {
position: fixed;
bottom: 2%;
right: 1%;
z-index: 1000;
// position: fixed; 
// bottom: 2%; 
// right: 1%; 
// z-index: 1000;
}

.body-notif {
.result-amount {
  margin-bottom: 8px;
  font-size: 10px;
}
.item-offer {
  font-size: 11px;
  background: white;
  border-radius: 10px;
  padding: 12px 10px 10px 13px;
  margin: 4px -2px 0 -2px;
}
.bottom-line {
    margin-top: 13px;
    display: block;
    font-size: 11px;
}
}

.offer-added {
font-weight: bold;
margin: 11px 0 0 0;
font-size: 12px;
color: #71bbfd;
}
.top-price {
font-size: 13px;
margin: -2px 0 -2px 0;
display: inline-block;
}
.low-high-open {
.col-md-4 {
  padding: 0;
}
}

.price-label {
text-align: left;
display: block;
font-size: 10px;
margin-bottom: 5px;
}

// .question-item {
//   cursor: pointer;
// }

// .item-asset {
//   background: lightcyan;
//   border: 1px solid #d6fbfb;
// }

  .accept-btn {
    position: absolute;
    right: 13px;
    top: 63px;
    padding: 4px 16px;
    font-size: 12px;
  }

  .pool-rewards {
    position: absolute;
    right: 52px;
    margin-top: -1px;
    margin-bottom: -10px;
    padding: 2px 9px;
    border-radius: 10px;
    display: block;
    //width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    color: white;
  }
}

.item-asset {
border: 1px solid #7979792e;
padding: 10px;
}

.asset-item-icon {
  width: 36px;
}


.modal-scroll {
height: 509px;
overflow: scroll;
}

.transform-txt {
transform: rotate(270deg);
position: absolute;
top: 17px;
left: 6px;
font-size: 10px;
color: grey !important;
}
.type-offer {
text-transform: capitalize;
float: right;
}
.subtite-offer {
margin: -7px 0 0 0;
}
.notifications-bc {
width: 360px !important;
}
.modal-form {
input {
border: 1px solid #f2f4f7;
color: #646777;
}
button {
 background: #f2f4f7;
 border: 1px solid #f2f4f7;
 width: 90px;
}
}
.issuer-domain {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //width: 185px;
  display: block;
}

.rating-asset {
font-size: 12px;
margin: -11px 0 0 -9px;
}

.arrow_box {
position: relative;
background: #555;
border: 2px solid #000000;
border-radius: 3px;
}
.arrow_box:after, .arrow_box:before {
right: 100%;
top: 50%;
border: solid transparent;
content: " ";
height: 0;
width: 0;
position: absolute;
pointer-events: none;
}

//.apexcharts-tooltip {
//  color: #fff;
//  transform: translateX(10px) translateY(10px);
//  overflow: visible !important;
//  white-space: normal !important;
//}
//
//.apexcharts-tooltip span {
//padding: 5px 10px;
//display: inline-block;
//}

.accept-token-sm-btn {
  font-size: 12px;
  padding: 2px 8px;
  margin: -3px 0 0 5px;
}

.parent-not-history {
  display: table;
}

.not-history {
  display: table-cell;
  vertical-align: middle;
}
.scam {
  position: absolute;
  top: 248px;
  width: 52%;
  left: 84px;
}
.limit-order {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-basis: 171px;
  margin-bottom: 10px;
  margin-top: 5px;

  div {
    background: #38373e7a;
    padding: 0 5px 2px;
    border-radius: 10px;
    color: gray !important;
    width: 34px;
    margin-right: 3px;
  }
}
.limit-order-buy {
  div {
    background: #4de1b51f;
  }
}
.limit-order-sell {
  div {
    background: #dd52661a;
  }
}

.limit-form {
  input {
    border-radius: 10px;
  }
}






